import * as React from "react";

const AuthTemplate: React.FC<{
  children: React.ReactNode | React.ReactNode[];
}> = ({ children }) => {
  return (
    <div className="auth-template-container">
      <div className="auth-template-content">{children}</div>
    </div>
  );
};

export default AuthTemplate;
