import * as React from "react";
import { createRoot } from "react-dom/client";
// import { Provider } from "react-redux";
// import App from "./app";
// import { store } from "./data/store";
// import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./uiElements/Components/ErrorBoundary";

import PlaceHolderPage from "./placeholderPage";

// store build number in the window for debugging from the browser console
// window["buildNumber"] = process.env.BUILD_NUMBER;

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      {/* <Provider store={store}> */}
      {/* <App /> */}
      {/* </Provider> */}
      <PlaceHolderPage />
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
