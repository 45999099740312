import * as React from "react";

/**
 * @todo [PD-78] replace with https://github.com/bvaughn/react-error-boundary ?
 */
class ErrorBoundary extends React.Component<
  {
    children: React.ReactNode | React.ReactNode[];
  },
  { error?: Error; hasError: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error, hasError: true };
  }

  componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    // logErrorToMyService(error, info.componentStack); TODO: [PD-78]
  }

  render() {
    if (this.state.hasError) {
      return (
        <div id="error-boundary">
          <p>Something went wrong</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
